import React from "react";

type ContactFormInterestProps = {
  interest: {
    id: string;
    interest: string;
  };
  index: number;
};

const ContactFormInterest: React.FC<ContactFormInterestProps> = ({
  interest,
  index,
}) => (
  <div className="modal__checkbox-input-container">
    <label className="modal__checkbox-label">
      <input
        type="checkbox"
        id={`interest${index}`}
        name="interest[]"
        value={interest.interest}
        className="modal__checkbox"
      />
      <span className="align-bottom">{interest.interest}</span>
    </label>
    <br />
  </div>
);

export default ContactFormInterest;
