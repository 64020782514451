import React from "react";

type ContactFormRoleProps = {
  role: any;
};

const ContactFormRole: React.FC<ContactFormRoleProps> = ({ role }) => (
  <>
    <option
      key={role.id}
      value={role.title}
      className="text-sm pl-4 lg:text-base"
    >
      {role.title}
    </option>
  </>
);

export default ContactFormRole;
