import React, { useEffect } from "react";
import { useStaticQuery, graphql } from "gatsby";
import sal from "sal.js";
import Header from "./header";
import Footer from "./footer";

const Layout = ({ menus, children }) => {
  const data = useStaticQuery<GatsbyTypes.SiteTitleQueryQuery>(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  // Initialize scroll animations
  useEffect(() => {
    sal();
  }, []);

  return (
    <div className="wrapper overflow-hidden">
      <h1 className="hidden">{data.site.siteMetadata.title}</h1>
      <div className="header-wrapper">
        <Header menus={menus} />
      </div>
      <div style={{ marginTop: 64 }}>{children}</div>
      <Footer menus={menus} />
    </div>
  );
};

export default Layout;
