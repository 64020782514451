import React from "react";
import { useStaticQuery, graphql, PageProps } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

const Footer = ({ menus }) => {
  const data = useStaticQuery<GatsbyTypes.FooterQuery>(graphql`
    query Footer {
      contentfulContactDetails {
        id
        companyName
        president
        capital
        address {
          address
        }
        email
      }
    }
  `);

  const footerMenu =
    menus !== null && menus !== undefined
      ? menus.find(menu => menu.type === "secondary")
      : null;

  return (
    <footer className="footer bg-secondary text-white">
      <div className="container section mx-auto py-10 xl:py-14 2xl:py-20">
        <div className="footer__content">
          <a href="https://hitotsu.co.jp" target={"_blank"}>
            <StaticImage
              className="footer__logo"
              src="../assets/images/logo-dark.png"
              alt="医療機器管理システム HITOTSU ロゴ"
              loading="lazy"
              placeholder="none"
              objectFit="contain"
            />
          </a>
          <ul className="footer__text-container mb-6">
            <li className="mb-2">
              <div className="item">
                <div className="item__content">
                  <p className="item__text">
                    運営会社：{data.contentfulContactDetails.companyName}
                  </p>
                </div>
              </div>
            </li>
            <li className="mb-2">
              <div className="item">
                <div className="item__content">
                  <p className="item__text">
                    代表者：{data.contentfulContactDetails.president}
                  </p>
                </div>
              </div>
            </li>
            <li className="mb-2">
              <div className="item">
                <div className="item__content">
                  <p className="item__text">
                    資本金：{data.contentfulContactDetails.capital}
                  </p>
                </div>
              </div>
            </li>
            <li className="mb-2">
              <div className="item">
                <div className="item__content">
                  <p className="item__text">
                    所在地：{data.contentfulContactDetails.address.address}
                  </p>
                </div>
              </div>
            </li>
            <li className="mb-2">
              <div className="item">
                <div className="item__content">
                  <p className="item__text">
                    お問い合わせ：{data.contentfulContactDetails.email}
                  </p>
                </div>
              </div>
            </li>
            <li className="mb-2">
              <div className="item">
                <div className="item__content">
                  <p className="item__text">
                    会社URL：
                    <a href="https://hitotsu.co.jp/" target={"_blank"}>
                      https://hitotsu.co.jp/
                    </a>
                  </p>
                </div>
              </div>
            </li>
          </ul>
          <div className="text-right xl:-mb-5 2xl:-mb-10">
            <a
              href="https://hitotsu.co.jp/privacy-policy"
              target="_blank"
              className="item__text mr-14 lg:mr-0"
            >
              Privacy Policy
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
