import React from "react";

type ContactFormSourceProps = {
  source: {
    id: string;
    source: string;
  };
  index: number;
};

const ContactFormSource: React.FC<ContactFormSourceProps> = ({
  source,
  index,
}) => (
  <div className="modal__checkbox-input-container">
    <label className="modal__checkbox-label">
      <input
        type="checkbox"
        id={`source${index}`}
        name="source[]"
        value={source.source}
        className="modal__checkbox"
      />
      <span className="align-bottom">{source.source}</span>
    </label>
    <br />
  </div>
);

export default ContactFormSource;
