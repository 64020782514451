import React, { useEffect, useState } from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import Modal from "./contactForm";

type HeaderProps = {
  menus: any;
};

const Header: React.FC<HeaderProps> = ({ menus }) => {
  let navMenuButton;
  let navMenu;

  const mainMenu =
    menus !== null && menus !== undefined
      ? menus.find(menu => menu.type === "primary")
      : null;

  const initSmoothScroll = () => {
    if (typeof window !== "undefined") {
      const SmoothScroll = require("smooth-scroll");

      const scroll = new SmoothScroll('a[href*="#"]', {
        speed: 800,
        speedAsDuration: true,
        easing: "easeOutQuart",
        offset: 90,
      });
    }
  };

  const toggleNav = () => {
    navMenu.classList.toggle("nav__menu--active");

    navMenuButton.classList.toggle("nav__menu-button--active");

    document.body.classList.toggle("disable-scroll");
  };

  const closeMenu = () => {
    if (navMenuButton.classList.contains("nav__menu-button--active")) {
      navMenuButton.classList.remove("nav__menu-button--active");

      navMenu.classList.remove("nav__menu--active");

      document.body.classList.remove("disable-scroll");
    }
  };

  useEffect(() => {
    navMenuButton = document.getElementById("nav_menu_button");

    navMenu = document.getElementById("nav_menu");

    initSmoothScroll();
  });

  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalInterestNum, setModalInterestNum] = useState(null);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  function setInterestNum(num) {
    setModalInterestNum(num);
    console.log(modalInterestNum);
  }

  return (
    <header className="header">
      <Link to="/">
        <StaticImage
          className="header__logo"
          src="../assets/images/logo.png"
          alt="医療機器管理システム HITOTSU ロゴ"
          loading="eager"
          placeholder="none"
          objectFit="contain"
          width={155}
          height={60}
          quality={80}
        />
      </Link>
      {mainMenu !== null && mainMenu !== undefined && (
        <nav className="nav">
          <h2 className="hidden">Top navigation</h2>
          <ul id="nav_menu" className="nav__menu">
            {mainMenu.menuItems.map(item => (
              <li key={item.id}>
                <a
                  className="text-tertiary"
                  href={item.url}
                  onClick={closeMenu}
                >
                  {item.title}
                </a>
              </li>
            ))}
            <li className="hidden md:block">
              <button
                id="header__btn-contact"
                className="btn btn--primary rounded-full"
                type="button"
                data-sal="fade"
                onClick={() => {
                  setInterestNum(null);
                  openModal();
                }}
              >
                問い合わせる
              </button>
            </li>
            <li className="md:hidden">
              <button
                className="text-tertiary"
                onClick={() => {
                  setInterestNum("interest0");
                  openModal();
                }}
              >
                資料請求フォームへ
              </button>
            </li>
            <li className="md:hidden">
              <button
                className="text-tertiary"
                onClick={() => {
                  setInterestNum(null);
                  openModal();
                }}
              >
                問い合わせる
              </button>
            </li>
            <Modal
              modalIsOpen={modalIsOpen}
              closeModal={() => {
                closeModal();
              }}
              interestNum={modalInterestNum}
            />
          </ul>

          <button
            id="nav_menu_button"
            className="nav__menu-button"
            aria-label="mobile menu"
            type="button"
            onClick={toggleNav}
          >
            <span />
            <span />
            <span>MENU</span>
          </button>
        </nav>
      )}
    </header>
  );
};

export default Header;
