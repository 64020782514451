import React, { useEffect } from "react";
import Modal from "react-modal";
import { useStaticQuery, graphql } from "gatsby";
import ContactFormRole from "./contactFormRoles";
import ContactFormInterest from "./contactFormInterests";
import Symbol from "../assets/images/symbol.inline.svg";
import ContactFormSource from "./contactFormSources";

const customStyles = {
  overlay: {
    zIndex: 100,
    backgroundColor: "rgba(0, 0, 0, 0.85)",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    padding: "10px",
    transform: "translate(-50%, -50%)",
    borderRadius: "10px",
    maxHeight: "calc(100vh - 2rem)",
    overflowY: "auto",
  },
};

Modal.setAppElement("#___gatsby");

type ContactFormProps = {
  modalIsOpen: boolean;
  closeModal: any;
  interestNum: string;
};

const ContactForm: React.FC<ContactFormProps> = ({
  modalIsOpen,
  closeModal,
  interestNum,
}) => {
  const data = useStaticQuery<GatsbyTypes.ContactFormQuery>(graphql`
    query ContactForm {
      contentfulContactForm {
        id
        heading
        subheading
        roleDescription
        roles {
          id
          title
        }
        interestDescription
        interests {
          id
          interest
        }
        sources {
          id
          source
        }
        message {
          id
          message
        }
      }
    }
  `);

  const content = data.contentfulContactForm;

  function checkInterest() {
    interestNum !== null
      ? document
          .querySelector(`#${interestNum}`)
          .setAttribute("checked", "checked")
      : null;
  }

  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={checkInterest}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Contact Form Modal"
      >
        <div className="modal__container">
          <button
            onClick={closeModal}
            className="absolute top-2 right-2 md:top-4 md:right-4 lg:top-6"
          >
            <span className="dli-close"></span>
          </button>

          <div className="modal__content">
            <div className="section__title-container mb-4">
              <h2 className="section__title text-base md:text-2xl">
                {content.heading}
              </h2>
              <Symbol
                className="section__symbol md:-mt-2"
                alt="医療機器管理システム HITOTSU ロゴ横棒"
                loading="eager"
              />
            </div>
            <div className="modal__description">{content.subheading}</div>

            <form
              className="flex flex-col justify-center items-center"
              name="contact"
              method="POST"
              netlify-honeypot="bot-field"
              data-netlify="true"
              action="/thank-you"
            >
              <input type="hidden" name="form-name" value="contact" />
              <p className="hidden">
                <label>
                  Don’t fill this out if you’re human:{" "}
                  <input name="bot-field" />
                </label>
              </p>
              <label htmlFor="hospital_name" className="modal__form-label">
                <span className="modal__form-required-text">必須</span>
                医療機関名
              </label>
              <input
                id="hospital_name"
                type="text"
                name="hospital_name"
                placeholder="ご所属の医療機関名をご入力ください"
                className="modal__form"
                required
              />
              <label htmlFor="first_name" className="modal__form-label">
                <span className="modal__form-required-text">必須</span>
                氏名
              </label>
              <div className="flex justify-center w-full">
                <input
                  id="first_name"
                  type="text"
                  name="first_name"
                  placeholder="姓"
                  className="modal__form flex-1 mr-1"
                  required
                />
                <input
                  type="text"
                  name="last_name"
                  placeholder="名"
                  className="modal__form flex-1 ml-1"
                  required
                />
              </div>

              <label htmlFor="role" className="modal__form-label">
                <span className="modal__form-required-text">必須</span>
                役職
              </label>
              <select id="role" name="role" className="modal__select" required>
                <option
                  value=""
                  className="modal__select-placeholder"
                  disabled
                  selected
                  hidden
                >
                  選択してください
                </option>
                {content.roles.map(role => (
                  <ContactFormRole role={role} key={role.id} />
                ))}
              </select>

              <label htmlFor="email" className="modal__form-label">
                <span className="modal__form-required-text">必須</span>
                メールアドレス
              </label>
              <input
                id="email"
                type="email"
                name="email"
                placeholder="メールアドレスをご入力ください"
                className="modal__form"
                required
              />

              <label htmlFor="phone" className="modal__form-label">
                <span className="modal__form-optional-text">任意</span>
                電話番号
              </label>
              <input
                id="phone"
                type="phone"
                name="phone"
                placeholder="※電話でのご連絡をご希望の場合はご入力ください"
                className="modal__form"
              />

              <label htmlFor="interest" className="modal__form-label">
                <span className="modal__form-required-text">必須</span>
                お問い合わせ内容
              </label>
              <div className="modal__form-checkbox">
                <p className="modal__interest-description">
                  {content.interestDescription}
                </p>
                {content.interests.map((interest, index) => (
                  <ContactFormInterest interest={interest} index={index} />
                ))}
                <label className="modal__checkbox-label">
                  <input
                    type="checkbox"
                    id="interest10"
                    name="interest[]"
                    value="その他"
                    className="modal__checkbox"
                  />
                  <span className="align-bottom">その他</span>
                </label>
                <br />
              </div>

              <label htmlFor="free-text" className="modal__form-label">
                <span className="modal__form-optional-text">任意</span>
                お問い合わせ内容の詳細
              </label>
              <textarea
                name="free-text"
                id="free-text"
                cols={30}
                rows={5}
                placeholder="上記以外で詳しく問い合わせしたい内容がございましたら、ご記入ください。"
                className="modal__textarea"
              />

              <label htmlFor="source" className="modal__form-label">
                <span className="modal__form-optional-text">任意</span>
                HITOTSUをお知りになったきっかけ
              </label>
              <div className="modal__form-checkbox">
                <p className="modal__interest-description">
                  {content.interestDescription}
                </p>
                {content.sources.map((source, index) => (
                  <ContactFormSource source={source} index={index} />
                ))}
                <br />
              </div>

              <label htmlFor="interest" className="modal__form-label">
                <span className="modal__form-required-text">必須</span>
                プライバシーポリシーへの同意
              </label>
              <label className="modal__checkbox-label w-full">
                <input
                  type="checkbox"
                  id="privacy-policy"
                  name="privacy-policy"
                  value="privacy-policy"
                  className="modal__checkbox"
                  required
                />
                <span className="align-bottom">
                  <a
                    href="https://hitotsu.co.jp/privacy-policy"
                    target="_blank"
                    style={{ textDecoration: "underline" }}
                  >
                    プライバシーポリシー
                  </a>
                  を確認の上、内容に同意します
                </span>
              </label>
              <div className="modal__btn-container">
                <button
                  type="submit"
                  className="btn btn--primary py-0 my-4 lg:text-base lg:py-1 lg:px-10"
                >
                  送信
                </button>
              </div>
              <div className="modal__message-container">
                <p className="modal__message">
                  {typeof window !== "undefined" && window.innerWidth < 768
                    ? content.message.message
                    : content.message.message.replace("\n", "")}
                </p>
              </div>
            </form>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ContactForm;
